
import { defineComponent, onMounted, Ref, ref, inject } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import { useI18n } from 'vue-i18n';

type ButtonItem = {
  label?: string;
  to?: string;
  url?: string;
  command?: any;
  disabled?: boolean;
};

export default defineComponent({
  setup() {
    const auth: any = inject('Auth');
    const route = useRoute();
    const { t } = useI18n();
    const icon = ref();
    const error = ref();
    const description = ref();
    const button: Ref<ButtonItem> = ref({ label: `${t('messages.goHome')}`, to: '/' });

    onMounted(() => {
      switch (route.params.type) {
        case 'error':
          icon.value = { icon: 'icon-warning_outline', color: 'error-700' };
          error.value = t('messages.errorSomethingWhenWrong');
          description.value = t('messages.errorSomethingWhenWrongDescription');
          break;
        case 'access_denied':
          icon.value = { icon: 'icon-error_outline', color: 'warning-400' };
          error.value = t('messages.accessDenied');
          var roleTranslation = t(`roles.${route.params.info}`);
          var roleMessage = route.params.info ? ` ${t('common.loginWithRole', { role: roleTranslation })}` : '';
          description.value = `${t('messages.accessDeniedDescription')}${roleMessage}`;
          button.value = {
            label: `${t('login.logOut')}`,
            command: () => {
              auth.logout(window.location.origin);
            },
          };
          break;
        case 'no-grants':
          icon.value = { icon: 'icon-error_outline', color: 'warning-400' };
          error.value = t('messages.accessDenied');
          description.value = t('messages.accessDeniedDescription');
          button.value = {
            label: `${t('common.go-home')}`,
            command: async () => {
              await router.push('/');
            },
          };
          break;
        default:
          icon.value = { icon: 'icon-help_circle_outline', color: 'lavender-700' };
          error.value = t('messages.error404');
          break;
      }
    });

    const buttonClick = async (event: any, item: ButtonItem) => {
      event.preventDefault();
      if (item.disabled) {
        return;
      }
      if (item.command) {
        item.command({
          originalEvent: event,
          item: item,
        });
      }
      if (item.to) {
        await router.push(item.to);
      }
    };

    return { icon, error, description, button, buttonClick };
  },
});
